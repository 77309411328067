import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { connect } from "react-redux";
import ToggleButton from "../../../layout/ToggleButton";
import LoadingModal from "../../../layout/LoadingModal";
import { setConfiguration, setFileConfiguration } from "../../../../actions/config";
import { setAlert } from "../../../../actions/alert";
import placeholder from "../../../../img/Lablynx-logo.png";

const Config = ({ clientName, logo, loginText, useRoles, useOpenSocial, limsModule, displayLoginText, setConfiguration, setFileConfiguration, setAlert, setRoleActive }) => {
	const [formData, setFormData] = useState({
		clientName,
		logo: "",
		loginText,
		useRoles: useRoles === "true" ? true : false,
		useOpenSocial: useOpenSocial === "true" ? true : false,
		limsModule: limsModule ? limsModule : "GEN",
		displayLoginText: displayLoginText === "true" ? true : false,
	});
	const [newLogo, setNewLogo] = useState(logo ? `data:image/jpeg;base64, ${logo}` : placeholder);
	const [loadingModalShow, setLoadingModalShow] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			setLoadingModalShow(true);

			await setConfiguration("clientName", formData.clientName);
			await setConfiguration("loginText", formData.loginText);
			await setConfiguration("useRoles", formData.useRoles);
			await setConfiguration("useOpenSocial", formData.useOpenSocial);
			await setConfiguration("limsModule", formData.limsModule);
			setRoleActive(formData.useRoles);
			await setConfiguration("displayLoginText", formData.displayLoginText);

			if (formData.logo !== "") {
				await setFileConfiguration("logo", formData.logo);
			}

			await setAlert("Successfully Saved", "success", 1500);
		} catch (err) {
			await setAlert("Error Saving Changes", "danger", 1500);
		} finally {
			setLoadingModalShow(false);
		}
	};

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const handleCheckboxClick = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.checked });
	};

	const handleLogoChange = (e) => {
		const file = e.target.files[0];
		let data = new FormData();
		data.append("file", file);
		setFormData({ ...formData, [e.target.name]: data });
		setNewLogo(URL.createObjectURL(file));
	};

	return (
		<div>
			<LoadingModal modalShow={loadingModalShow} />
			<Form onSubmit={(e) => handleSubmit(e)} encType="multipart/form-data">
				<Form.Group className="mb-3 p-2 border-top">
					<Form.Label>
						<strong>Client Name</strong>
					</Form.Label>
					<Form.Control required type="text" name="clientName" value={formData.clientName} placeholder="Enter Client Name" onChange={(e) => handleChange(e)} />
				</Form.Group>
				<Form.Group className="mb-3 p-2 border-top">
					<Form.Label>
						<strong>Client Logo</strong>
					</Form.Label>
					<div className="mb-1 panel">{newLogo ? <img src={newLogo} className="img-fluid" alt="Logo" /> : "No Logo"}</div>
					<Form.Control id="file-upload" type="file" name="logo" onChange={(e) => handleLogoChange(e)} />
				</Form.Group>
				<Form.Group className="mb-3 p-2 border-top row">
					<div className="col-6">
						<Form.Label>
							<strong>Login Text</strong>
						</Form.Label>
						<Form.Control type="text" name="loginText" value={formData.loginText} placeholder="Enter Text" onChange={(e) => handleChange(e)} />
					</div>
					<div className="col-6">
						<Form.Label>
							<strong>Display After Login</strong>
						</Form.Label>
						<ToggleButton title="displayLoginText" name="displayLoginText" onClick={(e) => handleCheckboxClick(e)} checked={formData.displayLoginText} />
					</div>
				</Form.Group>
				<Form.Group className="mb-3 p-2 border-top">
					<Form.Label>
						<strong>Use Roles</strong>
					</Form.Label>
					<ToggleButton title="useRoles" name="useRoles" onClick={(e) => handleCheckboxClick(e)} checked={formData.useRoles} />
				</Form.Group>
				<Form.Group className="mb-3 p-2 border-top">
					<Form.Label>
						<strong>Use OpenSocial</strong>
					</Form.Label>
					<ToggleButton title="useOpenSocial" name="useOpenSocial" onClick={(e) => handleCheckboxClick(e)} checked={formData.useOpenSocial} />
				</Form.Group>
				<Form.Group className="mb-3 p-2 border-top">
					<Form.Label>
						<strong>LIMS Module</strong>
					</Form.Label>
					<Form.Control as="select" name="limsModule" value={formData.limsModule} onChange={(e) => handleChange(e)}>
						<option value="GEN">GEN</option>
						<option value="MEO">MEO</option>
					</Form.Control>
				</Form.Group>
				<Form.Group className="mb-3 p-2 border-top">
					<div className="text-center mb-5">
						<Button variant="primary" type="submit">
							Save Changes
						</Button>
					</div>
				</Form.Group>
			</Form>
		</div>
	);
};

const mapStateToProps = (state) => ({
	clientName: state.config.clientName,
	logo: state.config.logo,
	loginText: state.config.loginText,
	useRoles: state.config.useRoles,
	useOpenSocial: state.config.useOpenSocial,
	limsModule: state.config.limsModule,
	displayLoginText: state.config.displayLoginText,
});

export default connect(mapStateToProps, {
	setConfiguration,
	setFileConfiguration,
	setAlert,
})(Config);
