import React from "react";
import { Link } from "react-router-dom";

const ElnCard = props => {
  let url;
  if (props.searchQuery === 0) {
    url = "/projects/" + props.data.projectId + "/experiments/" + props.data.id;
  } else if (props.searchQuery === 1) {
    url = "/projects/" + props.data.id;
  } else {
    url = "/templates/" + props.data.id;
  }

  return (
    <div className={`col-12 col-md-6 col-lg-4 col-xl-3`}>
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">
            <Link to={url}>{props.data.title}</Link>
          </h5>
          <p className="description-text" readOnly>
            {props.data.description ? props.data.description : ""}
          </p>
          <div className="card-footer pl-0 pr-0">
            <p class="card-text m-0">
              <small class="text-muted">
                Created by <strong>{props.data.createdBy}</strong> -{" "}
                <strong>{props.data.createdAt.split(".")[0].replace("T", " ")}</strong>
              </small>
            </p>
            <p class="card-text m-0">
              <small class="text-muted">
                Last edit by <strong>{props.data.lastUpdateBy}</strong> -{" "}
                <strong>{props.data.updatedAt.split(".")[0].replace("T", " ")}</strong>
              </small>
            </p>
            {props.data.isPublished && (
              <p class="card-text m-0">
                <small class="text-muted">
                  Published by <strong>{props.data.publisher}</strong> -{" "}
                  <strong>{props.data.publishedAt.split(".")[0].replace("T", " ")}</strong>
                </small>
              </p>
            )}
            {props.data.isReviewed && (
              <p class="card-text m-0">
                <small class="text-muted">
                  Reviewed by <strong>{props.data.reviewer}</strong> -{" "}
                  <strong>{props.data.reviewedAt.split(".")[0].replace("T", " ")}</strong>
                </small>
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ElnCard;
