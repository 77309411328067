import React from "react";

const ToggleButton = ({ title, name, onClick, checked }) => {
    return (
        <>
            <input type="checkbox" id={name} name={name} className="toggle-input" onClick={e => onClick(e)} checked={checked}/>
            <label for={name} className="toggle-label">{title}</label>
        </>
    )
};

export default ToggleButton;