import React, { useState, useEffect } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import ModalButton from "../../../layout/ModalButton";
import ToggleButton from "../../../layout/ToggleButton";
import ClickableCard from "../../../layout/ClickableCard";
import Spinner from "../../../layout/Spinner";
import LoadingModal from "../../../layout/LoadingModal";
import { setAlert } from "../../../../actions/alert";
import { createProjectType, updateProjectType, getProjectTypes } from "../../../../utils/apiCall";
// import { createProjectTypeAction, updateProjectTypeAction } from "../../../../actions/projectType";

const Types = ({ setAlert, loading }) => {
	const [formCreateData, setFormCreateData] = useState({});
	const [modalShow, setModalShow] = useState(false);
	const [loadingModalShow, setLoadingModalShow] = useState(false);
	const [projectTypes, setProjectTypes] = useState([]);

	useEffect(() => {
		const getData = async () => {
			setProjectTypes(await getProjectTypes());
		};

		getData();
	},[]);

	const onChange = async (e) => {
		setFormCreateData({
			...formCreateData,
			[e.target.name]: e.target.value,
		});
	};

	const onSubmit = async (e) => {
		e.preventDefault();

		try {
			setModalShow(false);
			setLoadingModalShow(true);

			const { projectTypeName, shortName } = formCreateData;

			const newProjectType = await createProjectType(projectTypeName, shortName, true);

			if (newProjectType) {
				setProjectTypes([...projectTypes, newProjectType]);
				await setAlert("Successfully Saved", "success", 1500);
			}
		} catch (err) {
			await setAlert("Error Saving Changes", "danger", 1500);
		} finally {
			setLoadingModalShow(false);
		}
	};

	return (
		<>
			<LoadingModal modalShow={loadingModalShow} />
			<div className="pt-2 pb-2 border-top">
				<ModalButton
					button={
						<>
							<i className={`fas fa-plus`} />
							<span> New Project Type</span>
						</>
					}
					title={"New Project Type"}
					modalShow={modalShow}
					setModalShow={(bool) => setModalShow(bool)}
					onButtonClick={() => setModalShow(true)}
					body={
						<>
							<Form onSubmit={(e) => onSubmit(e)}>
								<Form.Group controlId="formProjectTypeName">
									<Form.Label>Type Name</Form.Label>
									<Form.Control required type="text" name="projectTypeName" placeholder="Enter Project Type Name" value={formCreateData.name} onChange={(e) => onChange(e)} />
								</Form.Group>
								<Form.Group controlId="formProjectTypeShortName">
									<Form.Label>Type Short Name</Form.Label>
									<Form.Control required type="text" name="shortName" placeholder="Enter Project Type Short Name" value={formCreateData.shortName} onChange={(e) => onChange(e)} />
								</Form.Group>
								<Container>
									<Button variant="primary" type="submit">
										{"Create"}
									</Button>
								</Container>
							</Form>
						</>
					}
				/>
			</div>
			<ul className="no-bullets p-0">
				{loading && <Spinner />}
				{!loading &&
					projectTypes &&
					projectTypes.map((projectType) => {
						return <NestedList setAlert={setAlert} projectType={projectType} setLoadingModalShow={(bool) => setLoadingModalShow(bool)} setProjectTypes={setProjectTypes} />;
					})}
			</ul>
		</>
	);
};

const NestedList = ({ setAlert, projectType, setLoadingModalShow, setProjectTypes }) => {
	const [displayContent, setDisplayContent] = useState(false);
	const [editModalShow, setEditModalShow] = useState(false);

	const onClick = (e) => {
		e.preventDefault();
		setDisplayContent(!displayContent);
	};

	const onEditClick = (e, bool) => {
		e.preventDefault();
		e.stopPropagation();
		setEditModalShow(bool);
	};

	return (
		<li key={projectType.projectTypeId}>
			<ClickableCard
				onClick={(e) => onClick(e)}
				body={
					<Row>
						<Col className="col-10">
							<span>{projectType.typeName}</span>
						</Col>
						<Col className="col-2">
							<ModalButton
								button={
									<>
										<span>Edit </span>
										<i className={`fas fa-edit`} />
									</>
								}
								title={"Edit"}
								body={
									<EditForm
										id={projectType.projectTypeId}
										name={projectType.typeName}
										shortName={projectType.shortName}
										active={projectType.active}
										setEditModalShow={(bool) => setEditModalShow(bool)}
										setLoadingModalShow={(bool) => setLoadingModalShow(bool)}
										setAlert={setAlert}
										setProjectTypes={setProjectTypes}
									/>
								}
								modalShow={editModalShow}
								setModalShow={(bool) => setEditModalShow(bool)}
								onButtonClick={(e) => onEditClick(e, true)}
							/>
						</Col>
					</Row>
				}
			/>
		</li>
	);
};

const EditForm = ({ id, name, shortName, active, setEditModalShow, setLoadingModalShow, setAlert, setProjectTypes }) => {
	const [formData, setFormData] = useState({
		id,
		name,
		shortName,
		active,
	});

	const handleCheckboxClick = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.checked });
	};

	const onEditChange = (e) => {
		e.preventDefault();
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const onEditSubmit = async (e) => {
		e.preventDefault();
		setEditModalShow(false);
		setLoadingModalShow(true);

		try {
			const { id, name, shortName, active } = formData;
			const updatedProjectType = await updateProjectType(id, name, shortName, active);

			if (updatedProjectType) {
				setProjectTypes(await getProjectTypes());
				setAlert("Successfully Saved", "success", 1500);
			}
		} catch (err) {
			setAlert("Error Saving Changes", "danger", 1500);
		} finally {
			setLoadingModalShow(false);
		}
	};

	return (
		<Form onSubmit={(e) => onEditSubmit(e)}>
			<Form.Group controlId="formEditProjectTypeName">
				<Form.Label>Name</Form.Label>
				<Form.Control required type="text" name="name" placeholder="Enter Project Type Name" value={formData.name} onChange={(e) => onEditChange(e)} />
			</Form.Group>
			<Form.Group controlId="formEditProjectTypeShortName">
				<Form.Label>Short Name</Form.Label>
				<Form.Control required type="text" name="shortName" placeholder="Enter Prooject Type Short Name" value={formData.shortName} onChange={(e) => onEditChange(e)} />
			</Form.Group>
			<Form.Group controlId="formEditProjectTypeShortName">
				<Form.Label>Active</Form.Label>
				<ToggleButton title="projectTypeActive" name="active" onClick={(e) => handleCheckboxClick(e)} checked={formData.active} />
			</Form.Group>
			<Container>
				<Button variant="primary" type="submit">
					{"Save"}
				</Button>
			</Container>
		</Form>
	);
};

const mapStateToProps = (state) => ({
	// projectTypes: state.projectType.projectTypes,
	loading: state.projectType.loading,
});

export default connect(mapStateToProps, {
	// createProjectTypeAction,
	// updateProjectTypeAction,
	setAlert,
})(Types);
